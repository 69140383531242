import React from "react"
import Base from "./Base"

import ComponentParser from "../helpers/ComponentParser"

const ResellersPageTemplate = ({ pageContext }) =>  {
	
	const {contentFiles, contentParsed} = pageContext
  
  return (
    <Base context={pageContext}>
			<ComponentParser key={pageContext} content={pageContext.blocks} contentParsed={contentParsed} contentFiles={contentFiles} />
    </Base>
  )
}

export default ResellersPageTemplate
